import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { LoggerService } from '../../services/logger/logger.service'
import { MethodsService, NUMERIC_SORT_ARRAY } from '../../services/methods/methods.service'
import { UserDataService } from '../../services/user-data/user-data.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import { UsersService } from '../../services/api/services/users.service'
import { PiIntelligenceService } from '../../services/api/services/pi-intelligence.service'
import { CxConsts } from '../../shared/typings/chameleonx.typings'

const DOMAIN_TEST_REGEX = /^(?:\*\.)?[a-z0-9]+(?:[\-.][a-z0-9]+)*\.[a-z]{2,6}$/

@Component({
  selector: '',
  templateUrl: './vendor-hunting.component.html',
  styleUrls: ['./vendor-hunting.component.scss']
})
export class PIVendorHuntingComponent<T extends ExtendedVendorGPTGroupedResult = ExtendedVendorGPTGroupedResult> extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'PI Vendor Hunting'
  readonly paginationName = 'GPT Vendor Results'

  vendors: T[] = []


  //modals
  showConfigurationModal: boolean
  vendorGPTConfiguration?: string



  //filters
  filterValue: string = ''
  readonly isEligibleToApplyRecommendations: boolean = false
  readonly canPerformAnyAction: boolean = false
  readonly isEligibleToDelete: boolean = false
  readonly isEligibleToIgnore: boolean = false
  readonly isEligibleQueryGPT: boolean = false
  readonly isEligibleToViewConfiguration: boolean = false
  readonly itemsPerPageFilter = [10, 20, 50, 100, 200, 500, 1000, 2000]
  filterUnknown: boolean = true
  withRecommendationsOnly: boolean = false
  showIgnored: boolean = false
  availableActions: { id: VendorGPTResultsRecommendationAction, text: string }[] = [
    { id: VendorGPTResultsRecommendationAction.CREATE_VENDOR, text: 'Create Vendor' },
    { id: VendorGPTResultsRecommendationAction.MERGE_VENDORS, text: 'Merge Vendors' },
    { id: VendorGPTResultsRecommendationAction.UPDATE_VENDOR_NAME, text: 'Update Vendor Name' },
    { id: VendorGPTResultsRecommendationAction.ADD_DOMAIN_TO_EXISTING_VENDOR, text: 'Append Domain' },
    { id: VendorGPTResultsRecommendationAction.UPDATE_VENDOR_TAGS, text: 'Update Tags' },
    { id: VendorGPTResultsRecommendationAction.UPDATE_VENDOR_DESCRIPTION, text: 'Update Description' },
  ]
  selectedActions: { id: VendorGPTResultsRecommendationAction, text: string }[] = []

  modelsAvailableForGPTQuery: string[] = []

  availableModels: { id: string, text: string }[] = []
  selectedModels: { id: string, text: string }[] = []

  //table
  readonly tableHeaders: TableHeader[]
  currentSortBy: string
  _isRefreshing: boolean = false
  selectedRecommendations: Set<ExtendedVendorGPTResultRecommendationObject> = new Set()

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  upperCasedString = MethodsService.upperCasedString

  sliceString = MethodsService.sliceString

  private _debounce: void | number
  private debounce_timeout: number = 1000
  protected readonly numericTypes = NUMERIC_SORT_ARRAY

  constructor (private _userData: UserDataService, private _int: PiIntelligenceService, private _users: UsersService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.isEligibleToViewConfiguration = this._userData.hasPermissions('/intelligence_feed/vendor_gpt_config', UserPermission.MAINTAINER)
    this.isEligibleToDelete = this._userData.hasPermissions('/intelligence_feed/delete_gpt_results', UserPermission.ADMIN)
    this.isEligibleToIgnore = this._userData.hasPermissions('/intelligence_feed/ignore_gpt_results', UserPermission.OWNER)
    this.isEligibleQueryGPT = this._userData.hasPermissions('/intelligence_feed/gpt_query_domains', UserPermission.OWNER)
    this.isEligibleToApplyRecommendations = this._userData.hasPermissions('/intelligence_feed/set_gpt_recommendations', UserPermission.OWNER)
    this.canPerformAnyAction = this.isEligibleToDelete || this.isEligibleToIgnore || this.isEligibleQueryGPT
    this.currentItemsPerPage = 50
    this.tableHeaders = [
      //@formatter:off
      this.canPerformAnyAction ? { /*width: '4', */  name: '',                   sortable: false,    field: ''                   } : undefined,
      { width: '7',       name: 'Updated',              sortable: true,     field: 'updatedAt'          },
      { width: '8',       name: 'Last Scan',            sortable: true,     field: 'lastScanned'        },
      { width: '8',       name: 'Model',                sortable: true,     field: 'gptModel'           },
      { width: '10',      name: 'Domain',               sortable: true,     field: 'domain'             },
      { width: '9',       name: 'Vendor',               sortable: true,     field: 'vendorName'         },
      { width: '10',      name: 'Category',             sortable: true,     field: 'category'           },
      { width: '13',      name: 'Description',          sortable: true,     field: 'vendorDescription'  },
      { width: '11',      name: 'Tags',                 sortable: true,     field: 'tags'               },
      { width: '8',       name: 'Customers',            sortable: true,     field: 'paymentPageCustomerIdsLen' },
      { width: '8',       name: 'Risk Score',           sortable: true,     field: 'domainScore.risk'   },
      { width: '8',       name: 'AI Score',             sortable: true,     field: 'confidence'         },
      { width: '5',       name: 'Recommendations',      sortable: false                                 },
      //@formatter:on
    ].filter(x => !!x)

    this.currentSortBy = this.tableHeaders[2].field
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.showConfigurationModal) {
      return
    }
    if (key === 'Escape') {
      this.clearSelections()
    }
  }

  onFilterChange (item: any) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(), this.debounce_timeout) as any as number
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  openConfigurationModal () {
    this.showConfigurationModal = true
    this.ref_hljs()
  }

  clearSelections () {
    this.showConfigurationModal = false
    this.selectedRecommendations.clear()
    this.selectedTableItems.clear()
  }

  ref_hljs () {
    setTimeout(() => {
      this._zone.run(() => {})
      document.querySelectorAll('pre code').forEach((block) => hljs.highlightBlock(block))
    }, 10)

  }

  toggleRecommendation (recommendation: ExtendedVendorGPTResultRecommendationObject) {
    if (!this.isEligibleToApplyRecommendations || recommendation._disabled) {
      return
    }
    const sibling = recommendation.action == VendorGPTResultsRecommendationAction.MERGE_VENDORS ?
      this.vendors.find(v => v.recommendations.find(r => r == recommendation))!.recommendations
        .filter(r => r != recommendation)[0] :
      null
    if (this.selectedRecommendations.has(recommendation)) {
      this.selectedRecommendations.delete(recommendation)
      if (sibling) {
        sibling._disabled = false
      }
    } else {
      this.selectedRecommendations.add(recommendation)
      if (sibling) {
        sibling._disabled = true
      }
    }
  }

  openVendorInNewTab (str: string | string[]) {
    const search = encodeURIComponent(
      Array.isArray(str) ?
        Array.from(new Set(str.filter(Boolean)))
          .map(v => `^${v}$`)
          .join('|') :
        str
    )
    window.open(location.origin + `/${CxConsts.cxRoutingTable.INTELLIGENCE}?filter=${search}&search=${search}#vendors`, '_blank')
  }

  openAkamaiAccountsInNewTab (doc: T) {
    const search = encodeURIComponent(
      doc.customers
        .filter(Boolean)
        .map(c => c.akamaiCustomerId)
        .filter(Boolean)
        .map(n => `^${n}$`)
        .join('|')
    )
    window.open(location.origin + `/${CxConsts.cxRoutingTable.AKAMAI_CUSTOMERS}?filter=${search}`, '_blank')
  }

  openTenantsInNewTab (doc: T) {
    const search = encodeURIComponent(
      doc.customers
        .filter(Boolean)
        .map(c => c.name)
        .filter(Boolean)
        .map(n => `^${n}$`)
        .join('|')
    )
    window.open(location.origin + `/${CxConsts.cxRoutingTable.CUSTOMERS}?filter=${search}`, '_blank')
  }

  async deleteGPTResults () {
    const title = `Delete GPT Results Confirmation`
    const content = `Delete ${this.selectedTableItems.size} GPT Vendor Result${this.selectedTableItems.size > 1 ? 's' : ''} ?`
    const deleteConfirmationText = 'Delete'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {
      try {
        SpinnerService.spin('mini')
        const success = await this._int.deleteGPTResults([...this.selectedTableItems])
        if (success) {
          await this.refreshTable()
        }
      } catch (e) {
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  async ignoreGPTResults (ignore: boolean) {
    const _i = `${ignore ? 'Ignore' : 'Restore Ignored'}`
    const title = `${_i} GPT Results Confirmation`
    const content = `${_i} ${this.selectedTableItems.size} GPT Result Domain${this.selectedTableItems.size > 1 ? 's' : ''}?<br>note: GPT result is ignored by domain`
    const deleteConfirmationText = 'Confirm'
    if (await MethodsService.confirm(title, content, deleteConfirmationText)) {
      try {
        SpinnerService.spin('mini')
        const success = await this._int.ignoreGPTDomains([...this.selectedTableItems], ignore)
        if (success) {
          await this.refreshTable()
        }
      } catch (e) {
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  async queryGPTDomains (isQuery = false) {
    const prePromptDomains = isQuery ? undefined :
      Array
        .from(this.selectedTableItems)
        .map(_id => this.vendors.find(v => v._id == _id)!)
        .map(v => v.domain)
        .sort()
        .join(',')

    const prompt = await MethodsService.prompt(
      `Query GPT Domains`,
      `Type in comma separated domains in order to queue them for investigation at the Azure OpenAI chatgpt`,
      `akamai.com,akamai-cpc.com`,
      {
        title: 'Input Error',
        message: `Every domain must pass the following RegExp validation: ${DOMAIN_TEST_REGEX.toString()}`
      },
      'QUERY',
      (str: string) => {
        const domains = str.split(',')
        return domains.every(domain => DOMAIN_TEST_REGEX.test(domain))
      },
      false,
      { text: 'Select GPT Model', list: this.modelsAvailableForGPTQuery },
      prePromptDomains,
      '_vhdomainz'
    )
    if (!prompt) {
      return
    }

    const domains = prompt.input.split(',')
    const model = prompt.pickedFromList[0]

    try {
      SpinnerService.spin('mini')
      const preQuery = !isQuery ? null : await this.preFetchDomains(domains)
      if (preQuery?.count > 0) {
        SpinnerService.stop('mini')
        if (!await MethodsService.confirm(
          'Query Existing Results',
          `The following domains already exists:<br>
                   ${preQuery.data.map(d => `${d.domain}`).join(', ')}
                    <br>Proceed?`,
          `PROCEED`
        )) {
          return
        }
        SpinnerService.spin('mini')
      }
      const response = await this._int.queryGPTDomains(domains, model)
      if (response?.success) {
        MethodsService.toast('success', 'Success Queueing Domains', 'New domains results should appear within the dashboard shortly', 15)
        await this.refreshTable()
      } else {
        throw (response.data)
      }
    } catch (e) {
      MethodsService.toast('error', 'Error Queueing Domains', e.toString(), 15)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
    }
  }

  openNewTab (domain: string) {
    window.open(`//${domain}`, '_blank')
  }

  async applyRecommendations () {
    const recommendations = Array.from(this.selectedRecommendations)
    const title = `Apply GPT Recommendations Confirmation`
    const content = `Apply ${recommendations.length} Recommendation${recommendations.length > 1 ? 's' : ''} <br>Proceed?`
    if (await MethodsService.confirm(title, content, 'Apply')) {
      try {
        SpinnerService.spin('mini')
        const response = await this._int.applyRecommendations(recommendations)
        if (response?.success) {
          MethodsService.toast('success', 'Recommendations Applied Successfully', 'Results should appear at the Vendors screen.', 10)
          await this.refreshTable()
        } else {
          throw (response?.data)
        }
      } catch (e) {
        MethodsService.toast('error', 'Error Applying Recommendations', `${e.toString()}`, 15)
        LoggerService.error(e)
      }
      SpinnerService.stop('mini')
    }
  }

  async refreshTable (background: boolean = false, ignoreSelected: boolean = false): Promise<void> {
    if (this._isRefreshing || (!ignoreSelected && this.showConfigurationModal)) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        this.vendors = []
        this.clearSelections()
        SpinnerService.spin('mini')
      }

      const noop = async () => {}

      const [
        { data, count, filterOptions },
        gptConfiguration
      ] = await Promise.all([
        this.getVendorsGPTResults(),
        this.isEligibleQueryGPT ? this._int.getVendorGPTConfiguration() : (async () => {})()
      ])

      this.numOfAvailableDocs = count
      this.vendors = data as T[]
      if (gptConfiguration && gptConfiguration?.success) {
        this.vendorGPTConfiguration = JSON.stringify(gptConfiguration.data, null, 2)
      }

      //fill filters
      this.availableModels = filterOptions.models.map(model => ({ id: model, text: model }))
      this.modelsAvailableForGPTQuery = filterOptions.availableModels

    } catch (e) {
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }

  }

  private async getVendorsGPTResults (options?: FilterArguments<VendorGPTResultsFilterOptions>): Promise<VendorGPTResultsGetAllResponse> {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sortBy: this.currentSortBy,
      sortDirection: this.isSortReversed ? 1 : -1,
      filterUnknown: this.filterUnknown,
      withRecommendationsOnly: this.withRecommendationsOnly,
      showIgnored: this.showIgnored,
    }

    if (this.selectedActions.length) {
      options.recommendations = this.selectedActions.map(({ id }) => id)
    }

    if (this.selectedModels.length) {
      options.models = this.selectedModels.map(({ id }) => id)
    }

    if (this.selectedNumericFilterType.id == 0 && this.numericFilterSearch) {
      options.confidence = [this.currentNumericType.data, this.numericFilterSearch]
    }

    if (this.selectedNumericFilterType.id == 1 && (this.numericFilterSearchLow || this.numericFilterSearchHigh)) {
      options.confidenceRange = [this.numericFilterSearchLow || null, this.numericFilterSearchHigh || null]
    }

    return this._int.getAllVendorGPTResults(options)
  }

  private async preFetchDomains (domains: string[]): Promise<VendorGPTResultsGetAllResponse> {
    return this._int.getAllVendorGPTResults({
      domains,
      limit: domains.length,
      showIgnored: true,
      withRecommendationsOnly: false,
      filterUnknown: false
    })
  }

}
