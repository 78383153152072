import { Injectable } from '@angular/core'
import { PiInstancesProcessorService } from './pi-instances-processor.service'
import { MethodsService } from '../../methods/methods.service'

const numberToFixed = MethodsService.numberToFixed
const numberToSuffix = MethodsService.numberToSuffix
const integerWithCommas = MethodsService.integerWithCommas
const cxDateFormat = MethodsService.cxDateFormat

const KBtoGB = 1048576
const bytesToGB = 1073741824

@Injectable()
export class PiInstancesDashboardProcessorService extends PiInstancesProcessorService {

  static processInstances<T extends InstancesDashboardItem> (clusterOrClusters: ExtendedCustomerMappingSchema | ExtendedCustomerMappingSchema[], atlasInstances: ClusterMetrics[], beaconsData: AggregatedHitsByClusterQueryResult[]): T | T[] {
    const clusters = Array.isArray(clusterOrClusters) ? clusterOrClusters : [clusterOrClusters]

    return atlasInstances.map(instance => this.addDatabaseData(clusters, instance, beaconsData)) as T[]
  }

  private static addDatabaseData (_clusters: ExtendedCustomerMappingSchema[], _atlasInstance: ClusterMetrics, _beacons: AggregatedHitsByClusterQueryResult[]): InstancesDashboardItem {
    try {
      const _cluster = _clusters.find(c => c.clusterId.includes(_atlasInstance.clusterName.substring(7)))
      const matchingBeacon = _cluster ? _beacons?.find(b => b.clusterId === _cluster.clusterId) : undefined

      const cpuTrend = _atlasInstance?.metrics?.find(m => m.name === 'SYSTEM_NORMALIZED_CPU_USER')?.dataPoints
      const maxCpuTrend = _atlasInstance?.metrics?.find(m => m.name === 'MAX_SYSTEM_NORMALIZED_CPU_USER')?.dataPoints
      const storageInUseArr = _atlasInstance?.metrics?.find(m => m.name === 'DISK_PARTITION_SPACE_USED')?.dataPoints
      let storageInUse: number
      if (storageInUseArr?.length > 0) {
        storageInUse = storageInUseArr.slice(-1)[0]?.value
      }

      const mergedCpuTrend = mergeTrends([cpuTrend, maxCpuTrend], ['average', 'max'])
      const processedMemory = processMemoryTrend(_atlasInstance)

      const beacons = beaconsManipulate(matchingBeacon, 'clusterTotalHits')

      const mongoTier = {
        value: _atlasInstance?.mongoTier,
        trendClass: getEventTrendClass(_atlasInstance?.mongoTierHistory),
        title: eventTrendTitle(_atlasInstance?.mongoTierHistory),
        autoScalingTitle: autoScalingTitle(_atlasInstance?.autoScaling)
      }

      const storage = {
        size: _atlasInstance?.diskSizeGB,
        trendClass: getEventTrendClass(_atlasInstance?.storageHistory),
        title: eventTrendTitle(_atlasInstance?.storageHistory)
      }

      const memory = {
        size: Number(processedMemory?.lastSize),
        trend: processedMemory?.trend,
        activeAverageUse: Number(processedMemory?.lastAverage),
        activeMaxUse: Number(processedMemory?.lastMax)
      }

      const totalJS = {
        total: numberToSuffix(matchingBeacon?.totalJS, 2, true),
        raw: integerWithCommas(matchingBeacon?.totalJS),
        sort: matchingBeacon?.totalJS,
      }

      return {
        _id: _cluster?._id,
        clusterId: _cluster?.clusterId,
        clusterName: _cluster?.clusterName,
        instanceId: _atlasInstance?.clusterName,
        clusterEndpoint: _cluster?.metadata?.backendEndpoint,
        imageEnvironment: _cluster?.imageEnvironment,
        environment: _cluster?.environment,
        groupId: _atlasInstance?.groupId,
        akamaiIds: _cluster?.akamaiIds,
        akamaiIdsLength: _cluster?.akamaiIdsLength,
        tenants: _cluster?.tenants,
        tenantsLength: _cluster?.tenantsLength,
        primaryProcess: _atlasInstance?.processId,
        diskType: _atlasInstance?.diskType,
        provider: _atlasInstance?.provider,
        cpuTrend: mergedCpuTrend,
        storageInUse: numberToFixed(storageInUse / bytesToGB, 2),
        memory,
        beacons,
        totalJS,
        mongoTier,
        storage
      } as InstancesDashboardItem
    } catch (e) {
      console.error(`error processing instance ${_atlasInstance?.clusterName} ${e}`)
      return
    }
  }

  static processTenants<T = ExtendedCustomerMappingSchema & InstanceDashboardTenant[] & {}> (cluster: ExtendedCustomerMappingSchema, beacons: AggregatedHitsPerTenantQueryResult[], allTenants: MappedInstanceDashboardTenants): T {
    let associatedTenants = (cluster.tenants as any as string[]).map(tenantId => {
      const tenant = allTenants.find(t => t.id == tenantId) as unknown as InstanceDashboardTenant
      const matchingBeacons = beacons?.find(b => b._id == tenantId)
      tenant.beacons = beaconsManipulate(matchingBeacons, 'tenantTotalHits')
      tenant.rawBeacons = matchingBeacons?.tenantTotalHits
      return tenant ?? null
    }).filter(x => !!x).sort(({ name: a }, { name: b }) => a > b ? 1 : b > a ? -1 : 0) as []
    const accountBeacons = this.processAccountBeacons(associatedTenants)

    return { ...cluster, associatedTenants, accountBeacons } as unknown as T
  }

  static updateTenantBeacons (_tenants: InstanceDashboardTenant[], _beacons: AggregatedHitsPerTenantQueryResult[]): {tenants: InstanceDashboardTenant[], allBeacons: {}}{
    const tenants = _tenants.map(tenant => {
      const matchingBeacons = _beacons?.find(b => b._id == tenant.id)
      tenant.beacons = beaconsManipulate(matchingBeacons, 'tenantTotalHits')
      tenant.rawBeacons = matchingBeacons?.tenantTotalHits
      return tenant
    })
    const allBeacons = this.processAccountBeacons(tenants)
    return {tenants, allBeacons}
  }

  private static processAccountBeacons (tenants: InstanceDashboardTenant[]): {} {
    let accountBeacons = {}

    tenants.forEach((tenant: InstanceDashboardTenant) => {
      accountBeacons[tenant.akamaiId] = accountBeacons[tenant.akamaiId] ?
        accountBeacons[tenant.akamaiId] + (tenant.beacons?.sort ?? 0)
        : tenant.beacons?.sort
    })
    return accountBeacons
  }

  static processConfigs (_tenantsWithConfigs: CustomersByEnv[]): { [key: string]: { hasConfig: boolean, hasConfigDiff: boolean | undefined } } {
    let results = {}
    _tenantsWithConfigs.forEach(tenant => {
      const _id = tenant.staging._id
      let hasConfig: boolean = false
      let hasConfigDiff: boolean = false
      if (!tenant.updateAvailable && !!tenant.staging.customerConfig && !!tenant.production.customerConfig) {
        hasConfig = true
        hasConfigDiff = false
      } else if (!tenant.updateAvailable && !tenant.staging.customerConfig && !tenant.production.customerConfig) {
        hasConfig = false
        hasConfigDiff = undefined
      } else if (tenant.updateAvailable) {
        hasConfig = true
        hasConfigDiff = true
      }
      results[_id] = { hasConfig, hasConfigDiff }
    })
    return results
  }

  static processDownloadClusters (data: InstancesDashboardItem[]): StandardObject[] {
    return data.map(item => {
      let newItem = { ...item } as StandardObject

      delete newItem.tenants
      delete newItem.akamaiIds
      delete newItem.cpuTrend

      newItem.lastAverageMemory = item.memory?.activeAverageUse
      newItem.lastMaxMemory = item.memory?.activeMaxUse
      newItem.beacons = item.beacons?.total
      newItem.totalJS = item.totalJS?.total
      newItem.mongoTier = item.mongoTier?.value
      newItem.storage = item.storage?.size
      return newItem
    })
  }

  static processDownloadTenants (customersWithoutConfig: MappedInstanceDashboardTenants): StandardObject[] {
    return customersWithoutConfig.map((item) => {
      let newItem = { ...item } as StandardObject
      if (item?.agentVersions?.dry) {
        newItem.agentVersionStag = item.agentVersions?.dry?.version
        newItem.agentVersionProd = item.agentVersions?.wet?.version
      }
      delete newItem?.agentVersions
      delete newItem?.beacons
      delete newItem?.rawBeacons
      return newItem
    })
  }

  static getMappedTenants (data: (InstanceExtendedCustomer[])): MappedInstanceDashboardTenants {
    return data.map(({ akamaiCustomerId, clusterId, _id, name, website, engineVersions, akamaiAccountName }) => ({
      clusterId,
      id: _id,
      name,
      website,
      akamaiId: akamaiCustomerId,
      akamaiAccountName,
      agentVersions: engineVersions
    }))
  }
}

function getEventTrendClass (events?: GetAllEventsResponse[]): string {
  const _class = `font-size-20 relative font-weight-bolder mdi mdi-trending-`
  const up = `${_class}up color-cx-accent-dark`
  const down = `${_class}down color-cx-green top-6px`
  const neutral = `${_class}neutral color-cx-accent top-3px`
  if (!events) return neutral
  return (events[0].currentSize < events[0].previousSize) ? down : up
}

function eventTrendTitle (events?: GetAllEventsResponse[]): string {
  const maxEventsAmount = 15
  if (!events) return 'No change'
  return events.slice(0, maxEventsAmount)
    .map(e => `${cxDateFormat(e.timestamp).localDts}:  ${e.previousSize}`)
    .join('\n')
}

function autoScalingTitle (autoScaling?: { minInstanceSize: string; maxInstanceSize: string }): string {
  if (!autoScaling?.minInstanceSize) return 'No auto scaling'
  return `Auto Scaling\n-------------------\nMin:  ${autoScaling.minInstanceSize}\nMax: ${autoScaling.maxInstanceSize}`
}

function processMemoryTrend (memoryObject: ClusterMetrics): {
  trend: MemoryTrendObject[],
  lastAverage: string,
  lastMax: string,
  lastSize: string
} {
  if (!Array.isArray(memoryObject?.metrics)) return null
  let updatedTrendObject: MemoryTrendObject[] = []
  let percentTrend = getPercentFromMemoryMetrics(memoryObject.metrics)

  let [trend, maxTrend, availableMemory] = [
    memoryObject.metrics.find(m => m.name === 'SYSTEM_MEMORY_USED').dataPoints,
    memoryObject.metrics.find(m => m.name === 'MAX_SYSTEM_MEMORY_USED').dataPoints,
    memoryObject.metrics.find(m => m.name === 'SYSTEM_MEMORY_AVAILABLE').dataPoints
  ]

  updatedTrendObject = trend.map(t => {
    const availableMemoryItem = availableMemory.find(m => m.timestamp === t.timestamp).value
    const percentItem = percentTrend.find(m => m.timestamp === t.timestamp).value
    const usedMemoryItem = t.value
    const maxMemoryItem = maxTrend.find(m => m.timestamp === t.timestamp).value
    return {
      timestamp: t.timestamp,
      usableMemory: `${numberToFixed((availableMemoryItem + usedMemoryItem) / KBtoGB, 3)} GB`,
      memorySize: `${getActualMemory((availableMemoryItem + usedMemoryItem) / KBtoGB)} GB`,
      averageUsage: `${numberToFixed(usedMemoryItem / KBtoGB, 3)} GB`,
      relativeAverage: `${percentItem.toFixed(2)} %`,
      maxUsage: `${numberToFixed(maxMemoryItem / KBtoGB, 3)} GB`,
      relativeMax: `${((maxMemoryItem / KBtoGB) / getActualMemory((availableMemoryItem + usedMemoryItem) / KBtoGB) * 100).toFixed(2)} %`,
    }
  })
  return {
    trend: updatedTrendObject,
    lastAverage: Number(updatedTrendObject[updatedTrendObject.length - 1]?.averageUsage
      .split(' ', 1).toString()).toFixed(1),
    lastMax: Number(updatedTrendObject[updatedTrendObject.length - 1]?.maxUsage
      .split(' ', 1).toString()).toFixed(1),
    lastSize: updatedTrendObject[updatedTrendObject.length - 1]?.memorySize
      .split(' ', 1).toString()
  }
}

function mergeTrends<T extends Partial<AtlasApiMetricsInternal>[]> (trends: T[], names: string[]): {}[] {
  const fix = numberToFixed

  if (!Array.isArray(trends) || trends[0] == undefined) return null
  const mainTrend = trends[0]

  return mainTrend.map(t => {
    let trendObject = {}
    trendObject['timestamp'] = t.timestamp
    trendObject[names[0] || 'value'] = fix(t.value)
    for (let i = 1; i < trends.length; i++) {
      const matchingTrend = trends[i].find(trend => trend.timestamp == t.timestamp)
      trendObject[names[i] || `value${i}`] = fix(matchingTrend.value)
    }
    return trendObject
  })
}

export function beaconsManipulate (beacons: AggregatedHitsByClusterQueryResult | AggregatedHitsPerTenantQueryResult, key: string): BeaconsObject {
  if (!beacons) return
  return {
    total: numberToSuffix(beacons[key], 2, true),
    raw: integerWithCommas(beacons[key]),
    sort: beacons[key],
    trend: beacons?.trend
  }
}

function getPercentFromMemoryMetrics (rawMetrics: AtlasApiMetrics[]) {
  try {
    const usedMemoryMetrics = rawMetrics.find(metric => metric?.name === 'SYSTEM_MEMORY_USED')?.dataPoints
      .filter(value => value.value !== null)
    const availableMemoryMetrics = rawMetrics.find(metric => metric?.name === 'SYSTEM_MEMORY_AVAILABLE')?.dataPoints
    return usedMemoryMetrics?.map(usedMemoryMetric => {
      const usedMemory = usedMemoryMetric.value
      const availableMemory = availableMemoryMetrics?.find(availableMemoryMetric => availableMemoryMetric.timestamp === usedMemoryMetric.timestamp)?.value
      if (!availableMemory) {
        return {
          timestamp: usedMemoryMetric.timestamp,
          value: null
        }
      } else return {
        timestamp: usedMemoryMetric.timestamp,
        value: (usedMemory / (availableMemory + usedMemory)) * 100
      }
    }) as AtlasApiMetricsInternal[]
  } catch (err) {
    console.error(`error getting memory metrics ${err.message}`)
    return []
  }
}

function getActualMemory (totalMemory: number) {
  const memorySize = memorySizes.find(size => (size - 0.125) > totalMemory)
  return memorySize ?? totalMemory
}

const memorySizes = [1.7, 2, 3.75, 4, 8, 16, 32, 64, 128, 256]



