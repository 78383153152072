import { Component, Input, NgZone, OnInit } from '@angular/core'
import { TableWithFilters } from '../../../absracts/table-with-filters'
import { MethodsService } from '../../../../services/methods/methods.service'
import { LoggerService } from '../../../../services/logger/logger.service'
import { SpinnerService } from '../../../../services/spinner/spinner.service'
import { GlobalNotification, GlobalState } from '../../../../app.state'
import { PiEventConsoleService } from '../../../../services/api/services/pi-event-console.service'
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap'

type ScopeTabKey = `${EventGroupsScope}-tab`

type EventGroupMatchedFilter = {
  name: string,
  filter: string,
  match: FILTER_MATCH_TYPE[],
  selectedMatch?: FILTER_MATCH_TYPE,
  value?: string,
  placeholder?: string,
  options?: { id: string, name: string }[],
}

@Component({
  selector: 'event-groups-table',
  templateUrl: './event-groups-table.component.html',
  styleUrls: ['./event-groups-table.component.scss'],
})
export class EventGroupsTableComponent extends TableWithFilters implements OnInit {

  @Input() tenantId: string

  tableHeaders: TableHeader[]
  readonly title = 'CPC Event Groups'
  readonly DEFECTS_QUERY_LIMIT = 10_000_000

  eventGroups: EventGroupData = {
    immediate: { count: 0, eventGroups: [] },
    review: { count: 0, eventGroups: [] },
    informational: { count: 0, eventGroups: [] },
    no_action: { count: 0, eventGroups: [] },
    mitigated_ignored_test: { count: 0, eventGroups: [] },
    total: { count: 0, eventGroups: [] },
    ui_filters: { count: 0, eventGroups: [] },
    defects: { count: 0, eventGroups: [] }
  }
  filtersData: string = ''

  //filters
  showFiltersMenu: boolean = false
  showDefectsOnly: boolean = false
  currentTime = this.timeFilter[5]

  availableGroupTypes = [
    { id: EVENT_GROUP_TYPE.SENSITIVE_DATA_TRANSMISSION, name: 'Sensitive Data Transmission' },
    { id: EVENT_GROUP_TYPE.SUSPICIOUS_SCRIPT_ACTIVITY, name: 'Suspicious Script Activity' },
    { id: EVENT_GROUP_TYPE.SUSPICIOUS_NETWORK_ACTIVITY, name: 'Suspicious Network Activity' },
  ]

  availableRiskFactors = [
    { id: EventGroupAPI.EventGroupUIRiskFactor.CREDENTIALS, name: 'Credentials' },
    { id: EventGroupAPI.EventGroupUIRiskFactor.CREDIT_CARD, name: 'Credit Card' },
    { id: EventGroupAPI.EventGroupUIRiskFactor.EMAIL, name: 'Email' },
    { id: EventGroupAPI.EventGroupUIRiskFactor.PII, name: 'PII' },
    { id: EventGroupAPI.EventGroupUIRiskFactor.SSN, name: 'SSN' },
    { id: EventGroupAPI.EventGroupUIRiskFactor.HIGH_RISK_HOSTNAME, name: 'High Risk Hostname' },
  ].sort((a, b) => a.name.localeCompare(b.name))

  eventGroupFilters: EventGroupMatchedFilter[] = [
    {
      name: 'Event Group IDs',
      filter: 'eventGroupIds',
      placeholder: '4df9c5c0,6754eb',
      match: [
        FILTER_MATCH_TYPE.MATCHES_ANY,
        FILTER_MATCH_TYPE.DOES_NOT_MATCH_ANY
      ],
    },
    {
      name: 'Group Types',
      filter: 'groupTypes',
      placeholder: `${EVENT_GROUP_TYPE.SUSPICIOUS_SCRIPT_ACTIVITY},${EVENT_GROUP_TYPE.SUSPICIOUS_NETWORK_ACTIVITY}`,
      options: this.availableGroupTypes,
      match: [
        FILTER_MATCH_TYPE.MATCHES_ANY,
        FILTER_MATCH_TYPE.DOES_NOT_MATCH_ANY
      ],
    },
    {
      name: 'Risk Factors',
      filter: 'uiRiskFactors',
      placeholder: `${EventGroupAPI.EventGroupUIRiskFactor.CREDENTIALS},${EventGroupAPI.EventGroupUIRiskFactor.EMAIL}`,
      options: this.availableRiskFactors,
      match: [
        FILTER_MATCH_TYPE.MATCHES_ANY,
        FILTER_MATCH_TYPE.DOES_NOT_MATCH_ANY
      ],
    },
    {
      name: 'Source Domains',
      filter: 'sourceDomains',
      placeholder: 'domain.com,another-domain.co.uk',
      match: [
        FILTER_MATCH_TYPE.MATCHES_ANY,
        FILTER_MATCH_TYPE.DOES_NOT_MATCH_ANY,
        FILTER_MATCH_TYPE.STARTS_WITH,
        FILTER_MATCH_TYPE.DOES_NOT_START_WITH,
        FILTER_MATCH_TYPE.ENDS_WITH,
        FILTER_MATCH_TYPE.DOES_NOT_END_WITH,
        FILTER_MATCH_TYPE.CONTAINS
      ],
    },
    {
      name: 'Destination Domains',
      filter: 'destinationDomains',
      placeholder: 'domain.com,another-domain.co.uk',
      match: [
        FILTER_MATCH_TYPE.MATCHES_ANY,
        FILTER_MATCH_TYPE.DOES_NOT_MATCH_ANY,
        FILTER_MATCH_TYPE.STARTS_WITH,
        FILTER_MATCH_TYPE.DOES_NOT_START_WITH,
        FILTER_MATCH_TYPE.ENDS_WITH,
        FILTER_MATCH_TYPE.DOES_NOT_END_WITH,
        FILTER_MATCH_TYPE.CONTAINS
      ],
    },
    {
      name: 'Source Vendors',
      filter: 'sourceVendors',
      placeholder: 'Delta Air Lines,Google LLC',
      match: [
        FILTER_MATCH_TYPE.MATCHES_ANY,
        FILTER_MATCH_TYPE.DOES_NOT_MATCH_ANY,
        FILTER_MATCH_TYPE.STARTS_WITH,
        FILTER_MATCH_TYPE.DOES_NOT_START_WITH,
        FILTER_MATCH_TYPE.ENDS_WITH,
        FILTER_MATCH_TYPE.DOES_NOT_END_WITH,
        FILTER_MATCH_TYPE.CONTAINS
      ],
    },
    {
      name: 'Destination Vendors',
      filter: 'destinationVendors',
      placeholder: 'Delta Air Lines,Google LLC',
      match: [
        FILTER_MATCH_TYPE.MATCHES_ANY,
        FILTER_MATCH_TYPE.DOES_NOT_MATCH_ANY,
        FILTER_MATCH_TYPE.STARTS_WITH,
        FILTER_MATCH_TYPE.DOES_NOT_START_WITH,
        FILTER_MATCH_TYPE.ENDS_WITH,
        FILTER_MATCH_TYPE.DOES_NOT_END_WITH,
        FILTER_MATCH_TYPE.CONTAINS
      ],
    },
  ]
  currentEventGroupFilters: typeof this.eventGroupFilters = []
  currentEventGroupAvailable: typeof this.eventGroupFilters = this.eventGroupFilters
  currentSortBy: string
  paginationName = 'Event Groups'
  filterValue: string = ''
  itemsPerPageFilter = [10, 20, 50, 100, 200, 500, 1000, 2000, 5000]
  lastKnownIpp: number

  availableUiStatuses = [
    { id: EventGroupAPI.EventGroupUIStatus.OPEN, name: 'Open' },
    { id: EventGroupAPI.EventGroupUIStatus.MITIGATED, name: 'Mitigated' },
    { id: EventGroupAPI.EventGroupUIStatus.IGNORED, name: 'Ignored' },
    { id: EventGroupAPI.EventGroupUIStatus.NO_ACTION_REQUIRED, name: 'No Action Required' },
  ]
  currentUiStatuses: typeof this.availableUiStatuses = []

  availableSeverities = [
    { id: 'CRITICAL', name: 'Critical' },
    { id: 'HIGH', name: 'High' },
    { id: 'MEDIUM', name: 'Medium' },
    { id: 'LOW', name: 'Low' },
    { id: 'NOTE', name: 'Note' },
  ]
  currentSeverities: typeof this.availableSeverities = []

  includeTestModeEventGroups: boolean = false

  //filters
  private _debounce: void | number
  private debounce_timeout: number = 1000

  currentScope: EventGroupsScope = EventGroupsScope.immediate
  activeScope: ScopeTabKey = 'immediate-tab'
  eventGroupTabs: { key: EventGroupsScope, name: string, color?: string, data?: string, ms?: number }[] = [
    { key: EventGroupsScope.immediate, name: 'Immediate Attention', color: 'red' },
    { key: EventGroupsScope.review, name: 'Review', color: 'yellow' },
    { key: EventGroupsScope.informational, name: 'Informational' },
    { key: EventGroupsScope.no_action, name: 'No Action Required' },
    { key: EventGroupsScope.mitigated_ignored_test, name: 'Mitigated, Ignored and Test' },
    { key: EventGroupsScope.total, name: 'Total' },
    { key: EventGroupsScope.ui_filters, name: 'UI Filters' },
    { key: EventGroupsScope.defects, name: 'Defects' }
  ]

  //state
  currentData: CountedEventGroups = { count: 0, eventGroups: [] }
  _isRefreshing: boolean = false

  //imports
  copyToClipboard = MethodsService.copyToClipboard
  numberToSuffix = MethodsService.numberToSuffix

  constructor (private _api: PiEventConsoleService, private _zone: NgZone, private _state: GlobalState) {
    super()
    this.currentItemsPerPage = this.lastKnownIpp = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      { width: '',    name: 'Status',             sortable: true,     field: 'UI_STATUS'          },
      { width: '',    name: 'Severity',           sortable: true,     field: 'UI_SEVERITY'        },
      { width: '',    name: 'Type | ID',          sortable: false,    field: ''                   },
      { width: '',    name: 'Risk Factor',        sortable: true,     field: 'UI_RISK_FACTOR'     },
      { width: '',    name: 'Hostname | Vendor',  sortable: false,    field: ''                   },
      { width: '',    name: 'First Seen',         sortable: true,     field: 'FIRST_SEEN'         },
      { width: '',    name: 'Last Seen',          sortable: true,     field: 'LAST_SEEN'          },
      { width: '',    name: 'Beacons',            sortable: true,     field: 'BEACONS'            },
      { width: '',    name: 'Actions',            sortable: false,    field: ''                   },
      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = 'LAST_SEEN'
  }

  ngOnInit (): void {
    this.refreshTable!(false, true)
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  addFilter (filter: typeof this.eventGroupFilters[0]) {
    this.currentEventGroupFilters.push(filter)
    this.currentEventGroupAvailable.splice(this.currentEventGroupAvailable.findIndex(x => x.name === filter.name), 1)
    this.currentEventGroupAvailable.sort((a, b) => a.name.localeCompare(b.name))
  }

  removeFilter (filter: EventGroupMatchedFilter) {
    this.currentEventGroupFilters.splice(this.currentEventGroupFilters.findIndex(x => x.name === filter.name), 1)
    this.currentEventGroupAvailable.push(filter)
    this.currentEventGroupAvailable.sort((a, b) => a.name.localeCompare(b.name))
  }

  onTabChange ($event: NgbTabChangeEvent) {
    const tab = $event.nextId as ScopeTabKey
    const scope = tab.split('-')[0] as EventGroupsScope
    this.currentScope = scope
    this.activeScope = tab
    this.currentData = this.eventGroups[scope]
    this.numOfAvailableDocs = this.currentData.count
    this.currentPage = 1
    switch (this.currentScope) {
      case EventGroupsScope.defects:
        this.lastKnownIpp = this.currentItemsPerPage == this.DEFECTS_QUERY_LIMIT ? this.lastKnownIpp : this.currentItemsPerPage
        this.currentItemsPerPage = this.DEFECTS_QUERY_LIMIT
        break
      default:
        this.currentItemsPerPage = this.itemsPerPageFilter.includes(this.currentItemsPerPage) ? this.currentItemsPerPage : this.lastKnownIpp
    }
  }

  async refreshTable (background: boolean = false, init: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true

    try {
      if (!background) {
        this.clearState()
        SpinnerService.spin('mini')
      }
      if (init) {
        await this.fetchQueries()
      } else {
        await this.fetchScope()
      }
      this.currentData = this.eventGroups[this.currentScope]
      this.numOfAvailableDocs = this.currentData.count
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching event groups', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this.ref_hljs()
      this._isRefreshing = false
    }
  }

  onFilterChange (all: boolean = false) {
    if (this._debounce) {
      this._debounce = clearTimeout(this._debounce)
    }
    this._debounce = setTimeout(() => this.refreshTable(false, all), this.debounce_timeout) as any as number
  }

  onEGFilterChange (filter: typeof this.eventGroupFilters[0]) {
    if (filter.selectedMatch && filter.value) {
      this.onFilterChange(true)
    }
  }

  getOptions (): EventGroupsFeedFilterArguments {
    const options: EventGroupsFeedFilterArguments = {
      fromDate: this.currentTime.time(),
      toDate: Date.now(),
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sortBy: this.currentSortBy,
      sortDirection: this.isSortReversed ? 1 : -1,
    }

    if (this.currentUiStatuses.length) {
      options.uiStatuses = this.currentUiStatuses.map(x => x.id).join(',')
    }

    if (this.currentSeverities.length) {
      options.severities = this.currentSeverities.map(x => x.id).join(',')
    }

    if (this.includeTestModeEventGroups) {
      options.includeTestMode = true
    }

    for (const { filter, selectedMatch, value } of this.currentEventGroupFilters) {
      if (!selectedMatch || !value) {
        continue
      }
      if (Array.isArray(value)) {
        options[filter] = value.map(x => x.id).join(',')
      } else {
        options[filter] = value
      }
      options[`${filter}MatchType`] = selectedMatch
    }

    this.filtersData = JSON.stringify(options, null, 2)
    return options
  }

  async fetchImmediate () {
    const options = this.getOptions()
    return this._api.getEventGroups(this.tenantId, {
      ...options,
      severities: 'HIGH,CRITICAL',
      uiStatuses: 'OPEN',
      includeTestMode: false
    })
  }

  openEventGroup (customerId: string, eventGroupId: string) {
    window.open(`/event_group?customerId=${this.tenantId}&eventGroupId=${eventGroupId}`, '_blank')
  }

  async fetchReview () {
    const options = this.getOptions()
    return this._api.getEventGroups(this.tenantId, {
      ...options,
      severities: 'MEDIUM,LOW',
      uiStatuses: 'OPEN',
      includeTestMode: false
    })
  }

  async fetchInformational () {
    const options = this.getOptions()
    return this._api.getEventGroups(this.tenantId, {
      ...options,
      severities: 'NOTE',
      uiStatuses: 'OPEN',
      includeTestMode: false
    })
  }

  async fetchNoAction () {
    const options = this.getOptions()
    return this._api.getEventGroups(this.tenantId, {
      ...options,
      uiStatuses: 'MONITORED,NO_ACTION_REQUIRED',
      includeTestMode: false
    })
  }

  async fetchMitigatedIgnoredTest () {
    const options = this.getOptions()
    return this._api.getEventGroups(this.tenantId, {
      ...options,
      uiStatuses: 'MITIGATED,IGNORED',
      includeTestMode: true
    })
  }

  async fetchTotal () {
    const options = this.getOptions()
    return this._api.getEventGroups(this.tenantId, {
      ...options,
      uiStatuses: 'OPEN,MITIGATED,IGNORED,MONITORED,NO_ACTION_REQUIRED',
      includeTestMode: true
    })
  }

  async fetchUIFilters () {
    const options = this.getOptions()
    return this._api.getEventGroups(this.tenantId, {
      ...options,
    })
  }

  async fetchDefects () {
    const options = this.getOptions()
    return this._api.getEventGroups(this.tenantId, {
      ...options,
      limit: this.DEFECTS_QUERY_LIMIT
    })
  }

  async fetchQueries () {
    const startTime = performance.now()
    await Promise.all([
      this.fetchImmediate()
        .then(res => this.addRequestData(EventGroupsScope.immediate, res, performance.now() - startTime)),
      this.fetchReview()
        .then(res => this.addRequestData(EventGroupsScope.review, res, performance.now() - startTime)),
      this.fetchInformational()
        .then(res => this.addRequestData(EventGroupsScope.informational, res, performance.now() - startTime)),
      this.fetchNoAction()
        .then(res => this.addRequestData(EventGroupsScope.no_action, res, performance.now() - startTime)),
      this.fetchMitigatedIgnoredTest()
        .then(res => this.addRequestData(EventGroupsScope.mitigated_ignored_test, res, performance.now() - startTime)),
      this.fetchTotal()
        .then(res => this.addRequestData(EventGroupsScope.total, res, performance.now() - startTime)),
      this.fetchUIFilters()
        .then(res => this.addRequestData(EventGroupsScope.ui_filters, res, performance.now() - startTime)),
      this.fetchDefects()
        .then(res => this.addRequestData(EventGroupsScope.defects, res, performance.now() - startTime)),
    ])
  }

  async fetchScope () {
    const startTime = performance.now()
    let promise: Promise<CountedEventGroups>
    switch (this.currentScope) {
      case EventGroupsScope.immediate:
        promise = this.fetchImmediate()
        break
      case EventGroupsScope.review:
        promise = this.fetchReview()
        break
      case EventGroupsScope.informational:
        promise = this.fetchInformational()
        break
      case EventGroupsScope.no_action:
        promise = this.fetchNoAction()
        break
      case EventGroupsScope.mitigated_ignored_test:
        promise = this.fetchMitigatedIgnoredTest()
        break
      case EventGroupsScope.total:
        promise = this.fetchTotal()
        break
      case EventGroupsScope.ui_filters:
        promise = this.fetchUIFilters()
        break
      case EventGroupsScope.defects:
        promise = this.fetchDefects()
        break

    }
    await promise
      .then(res => this.addRequestData(this.currentScope, res, performance.now() - startTime))
  }

  private clearState () {
    this.currentData = { count: 0, eventGroups: [] }
  }

  resetFilters () {
    this.eventGroupFilters.forEach(x => {
      delete x.selectedMatch
      delete x.value
    })
    this.currentEventGroupFilters = []
    this.currentEventGroupAvailable = this.eventGroupFilters
  }

  ref_hljs () {
    setTimeout(() => {
      this._zone.run(() => {})
      document.querySelectorAll('pre code').forEach((block) => hljs.highlightBlock(block))
    }, 10)

  }

  private addRequestData<T extends EventGroupsScope> (key: EventGroupsScope, data: EventGroupData[T], ms: number) {
    switch (key) {
      case EventGroupsScope.defects:
        const eventGroups = data.eventGroups.filter(x => x._isDefect)
        this.eventGroups[key] = { count: eventGroups.length, eventGroups }
        break
      default:
        this.eventGroups[key] = data
    }
    const reqObject = this.eventGroupTabs.find(r => r.key === key)!
    const copy = JSON.parse(JSON.stringify(this.eventGroups[key]))
    MethodsService.recursiveDeleteKeysWithPrefix(copy, '_')
    reqObject.data = JSON.stringify(copy, null, 2)
    ms && (reqObject.ms = ms)
  }

}
