import { Component, NgZone, OnDestroy, OnInit } from '@angular/core'
import { TableWithFilters } from '../../shared/absracts/table-with-filters'
import { SpinnerService } from '../../services/spinner/spinner.service'
import { MethodsService } from '../../services/methods/methods.service'
import { LoggerService } from '../../services/logger/logger.service'
import { CustomerConfigService } from '../../services/api/services/customer-config.service'
import { GlobalNotification, GlobalState } from '../../app.state'
import JSONEditor from 'jsoneditor'

@Component({
  selector: 'pi-customer-config',
  templateUrl: './customer-config.component.html',
  styleUrls: ['./customer-config.component.scss']
})

export class PICustomerConfigComponent extends TableWithFilters implements OnInit, OnDestroy {

  readonly title = 'PI Tenants Configs Management'

  filterValue: string = ''

  customers: CustomersByEnv[] = []
  defaults: Record<keyof EditableCustomer, any>

  //view
  selectedCustomer?: CustomersByEnv

  //edit
  selectedCustomerEdit?: CustomerWithConfig
  selectedCustomerConfig?: EditableCustomer
  selectedCustomerEditor?: JSONEditor

  //table
  readonly tableHeaders: TableHeader[]

  currentSortBy: string

  paginationName = 'Customers With Configs'

  _isRefreshing: boolean = false

  textToClip = MethodsService.copyToClipboard

  normalizeString = MethodsService.normalizeString

  constructor (private _configs: CustomerConfigService, private _zone?: NgZone, private _state?: GlobalState) {
    super()
    this.currentItemsPerPage = this.itemsPerPageFilter[1]
    this.tableHeaders = [
      //@formatter:off
      { /*width: '8', */    name: 'Created At',         sortable: true, field: 'createdAt'  },
      { /*width: '8', */    name: 'Last Modified',      sortable: true, field: 'updatedAt'  },
      { /*width: '12',*/    name: 'Tenant ID',          sortable: true, field: '_id'        },
      { /*width: '12',*/    name: 'Tenant Name',        sortable: true, field: 'name'       },
      { /*width: '12',*/    name: 'Tenant Website',     sortable: true, field: 'website'    },
      { /*width: '12',*/    name: 'Staging Config',     sortable: false                     },
      { /*width: '12',*/    name: 'Production Config',  sortable: false                     },
      { /*width: '12',*/    name: 'Status',             sortable: false                     },
      { /*width: '12',*/    name: 'Actions',            sortable: false                     }
      //@formatter:on
    ].filter(x => !!x)
    this.currentSortBy = this.tableHeaders[1].field
  }

  ngOnDestroy (): void {
    window.removeEventListener('keyup', this.registerModalListeners.bind(this))
  }

  ngOnInit (): void {
    window.addEventListener('keyup', this.registerModalListeners.bind(this))
    this.refreshTable!()
    this._state.subscribe(GlobalNotification.BACKGROUND_REFRESH, () => this.refreshTable(true))
  }

  registerModalListeners ({ key }: KeyboardEvent) {
    if (!this.selectedCustomer && !this.selectedCustomerEdit) {
      return
    }
    if (key === 'Escape') {
      this.resetView()
      this.resetEditor()
    }
  }

  async refreshTable (background: boolean = false): Promise<void> {
    if (this._isRefreshing) {
      return
    }
    this._isRefreshing = true
    try {
      if (!background) {
        this.selectedCustomer = undefined
        this.resetEditor()
        SpinnerService.spin('mini')
      }

      const [{ data }, defaults] = await Promise.all([
        this.getCustomersConfigs(),
        this._configs.getDefaults()
      ])
      const { staging, production } = data
      const { count: stagingCount, data: stagingData } = staging
      const { count: productionCount, data: productionData } = production

      this.defaults = defaults

      //validate
      // if (stagingCount !== productionCount || stagingData.some((c, i) => c._id !== productionData[i]._id)) {
      //   LoggerService.info('stagingData:', stagingData)
      //   LoggerService.info('productionData:', productionData)
      //   LoggerService.error(`stagingCount: ${stagingCount}, productionCount: ${productionCount}`)
      //   throw Error('Corrupted Data!')
      // }

      this.numOfAvailableDocs = stagingCount

      if (staging && production) {
        this.customers = stagingData.map((c, i) => ({
          staging: { ...c, customerConfig: this.fillDefaults(c.customerConfig) },
          production: { ...productionData[i], customerConfig: this.fillDefaults(productionData[i].customerConfig) },
          ...this.canUpdateCustomer(c, productionData[i])
        }))

        if (!background) {
          LoggerService.info(this.customers)
        }

      } else {
        throw Error('Bad Response')
      }
    } catch (e) {
      if (!background) {
        MethodsService.toast('error', 'Error fetching customers', e.toString(), 8)
      }
      LoggerService.error(e)
    } finally {
      if (!background) {
        SpinnerService.stop('mini')
      }
      this._zone.run(() => {})
      this._isRefreshing = false
    }
  }

  async viewConfig (customerId: string) {
    try {
      SpinnerService.spin('mini')
      const customer = await this._configs.getCustomer(customerId)
      if (customer) {
        this.selectedCustomer = {
          ...customer,
          ...this.canUpdateCustomer(customer.staging, customer.production)
        }
        LoggerService.info(this.selectedCustomer)
      }
    } catch (e) {
      MethodsService.toast('error', 'Error fetching customer ' + customerId, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  async editCustomerConfig (customerId: string) {
    try {
      SpinnerService.spin('mini')
      const customerConfig = await this._configs.getCustomer(customerId)

      if (!customerConfig) {
        throw Error('Failed retrieving customer config!')
      }

      customerConfig.staging.customerConfig = this.fillDefaults(customerConfig.staging.customerConfig)

      const indexOf = this.customers.indexOf(this.customers.find(c => c.staging._id == customerId)!)

      this.customers[indexOf] = {
        ...customerConfig,
        ...this.canUpdateCustomer(customerConfig.staging, customerConfig.production)
      }

      const conf = customerConfig.staging
      this.selectedCustomerEdit = conf
      this.selectedCustomerConfig = { ...conf.customerConfig }
      ;['_id', 'customerId', 'createdAt', 'updatedAt', '__v'].forEach(atr => delete this.selectedCustomerConfig[atr])
      setTimeout(() => {
        const container = document.getElementById('jsoneditor')
        this.selectedCustomerEditor = new JSONEditor(container)
        this.selectedCustomerConfig = this.fillDefaults(this.selectedCustomerConfig)
        this.selectedCustomerEditor.set(this.selectedCustomerConfig)
      }, 10)

    } catch (e) {
      MethodsService.toast('error', 'Error fetching customer ' + customerId, e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  resetView () {
    this.selectedCustomer = undefined
  }

  resetEditor () {
    this.selectedCustomerEdit = undefined
    this.selectedCustomerConfig = undefined
    this.selectedCustomerEditor = undefined
  }

  canUpdateStagingConfig (): boolean {
    const customerId = this.selectedCustomerEdit._id
    if (!this.selectedCustomerEditor) {return false}
    const customerConfig = this.selectedCustomerEditor.get()
    const { updateAvailable } = this.canUpdateCustomer({ customerConfig } as CustomerWithConfig, this.customers.find(c => c.staging?._id == customerId)!.staging)
    return updateAvailable
  }

  async saveStagingConfig () {
    try {
      SpinnerService.spin('mini')
      const success = await this._configs.modifyStagingConfig(this.selectedCustomerEdit._id, this.selectedCustomerEditor.get())
      if (success) {
        MethodsService.toast('success', 'Success', `Tenant ${this.selectedCustomerEdit._id} staging config saved successfully`, 8)
        await this.refreshTable!()
        this.resetEditor()
      }
    } catch (e) {
      MethodsService.toast('error', 'Error saving staging config', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  async pushStageToProd () {
    try {
      SpinnerService.spin('mini')
      const success = await this._configs.setCustomerConfigFromStaging(this.selectedCustomer.staging._id)
      if (success) {
        MethodsService.toast('success', 'Success', `Tenant ${this.selectedCustomer.staging._id} staging config pushed successfully to production`, 8)
        await this.refreshTable!()
        this.resetView()
      }
    } catch (e) {
      MethodsService.toast('error', 'Error setting prod config', e.toString(), 8)
      LoggerService.error(e)
    } finally {
      SpinnerService.stop('mini')
      this._zone.run(() => {})
    }
  }

  canUpdateCustomer (staging: CustomerWithConfig, production: CustomerWithConfig): { updateAvailable: boolean, changedFields?: string[] } {
    const result: { updateAvailable: boolean, changedFields?: string[] } = { updateAvailable: false }

    if (!staging.customerConfig) {
      return result
    }

    if (staging.customerConfig && !production.customerConfig) {
      result.updateAvailable = true
      return result
    }

    result.changedFields = []

    const stagingJson: Partial<CustomerConfigSchema> = {}
    const productionJson: Partial<CustomerConfigSchema> = {}

    for (const key in this.defaults) {
      if (!(key in staging.customerConfig)) {
        stagingJson[key] = this.defaults[key]
      }

      if (production.customerConfig && !(key in production.customerConfig)) {
        productionJson[key] = this.defaults[key]
      }

      stagingJson[key] = staging.customerConfig[key]
      if (production.customerConfig) {
        productionJson[key] = key in productionJson ? productionJson[key] : production.customerConfig[key]
      }
      if (!MethodsService.objectsAreEqual(stagingJson[key], productionJson[key])) {
        result.changedFields.push(key)
        result.updateAvailable = true
      }
    }

    return result
  }

  fillDefaults<T extends IfDefined<EditableCustomer>> (conf: T): T {
    if (!conf) {
      return conf
    }
    for (const key in this.defaults) {
      if (!(key in conf)) {
        conf[key] = this.defaults[key]
      }
    }
    return conf
  }

  private async getCustomersConfigs (options?: FilterArguments): Promise<ResultObject<CustomerConfigsResponse>> {
    options = options || {
      filter: this.filterValue,
      limit: this.currentItemsPerPage,
      page: this.currentPage - 1,
      sort_by: this.currentSortBy,
      sort_direction: this.isSortReversed ? 'asc' : 'desc'
    }
    return await this._configs.getCustomersConfigs(options)
  }

}
